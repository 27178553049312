.leaflet-draw-draw-iconMarker {
  background-image: url('../assets/icons/add_photo_alternate_black_24dp.svg') !important;
  background-size: 20px 20px !important;
}

.leaflet-draw-draw-textMarker {
  background-image: url('../assets/icons/text_fields_black_24dp.svg') !important;
  background-size: 20px 20px !important;
}

.leaflet-draw-draw-handDrawnPolygon {
  background-image: url('../assets/icons/border_style_black_24dp.svg') !important;
  background-size: 20px 20px !important;
}

.leaflet-draw-draw-handDrawnPolyline {
  background-image: url('../assets/icons/timeline_black_24dp.svg') !important;
  background-size: 20px 20px !important;
}