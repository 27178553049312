.leaflet-secondtop {
  margin-top: 60px;
  z-index: 999;
}

.leaflet-secondleft {
  left: 60px;
  z-index: 999;
}

.leaflet-thirdtop {
  margin-top: 120px;
  z-index: 999;
}

.leaflet-thirdleft {
  left: 60px;
  z-index: 999;
}

.leaflet-top.leaflet-left {
  z-index: 1001;
}

// Popup
.leaflet-popup-content-wrapper {
  border-radius: 4px;
  padding: 2px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}
.leaflet-popup-content {
  margin: 6px 0;
  padding: 0 6px;
}
.leaflet-popup-tip {
  width: 13px;
  height: 13px;
}
