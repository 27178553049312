@import 'loader';
@import 'tree';
@import 'leaflet';
@import 'areaClusterGroup';
@import 'leafletDraw';

body {
  font-family: 'Roboto', sans-serif;
}

a {
  all: unset;
  cursor: pointer;
}

.NegativeMargin {
  margin-left: -68px !important;
}

.user-info-container {
  display: flex !important;
  .user-info {
    margin: 3px;
  }
  .user-info:not(:first-child):before {
    content: ' | ';
  }
}

.leaflet-zoom-animated {
  pointer-events: all;
}

.leaflet-editing-icon {
  border-radius: 20px;
}

.leaflet-markerIcon {
  border-radius: 50%;
}
