.area-cluster {
  min-width: 36px;
  min-height: 36px;
  text-align: center;
  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;

  background-color: rgba(50, 135, 225, 0.644);

  display: flex;
  justify-content: center;
  align-items: center;
}

.area-cluster div {
  width: 28px;
  height: 28px;
  background-color: rgb(50, 135, 225);
}

.area-cluster div span {
  line-height: 28px;
  color: white;
}
